import React, { useContext } from 'react';
import { func, number, string, bool } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorProvider';
import { checkForAnyErrors, hasError } from '../../../../helpers/errorHandlingUtil';
import { clickAddAreaAnalytics } from '../../../../analytics';

export const AddAreaButton = ({ onAddAreaClick, selectedArea, variant, disabled }) => {
  const {
    addElement,
    calculate: {
      areas,
      coverage
    },
    calculatorType,
    setCalculateStateAndRef
  } = useContext(CalculatorContext);

  const hasDefaultAreaOnly = selectedArea === null && !coverage;

  return (
    <Button
      fullWidth
      onClick={() => {
        const strictErrors = checkForAnyErrors({
          calculatorType, areas
        });
        const isError = hasError(areas, strictErrors);
        const currentAreaName = areas[selectedArea]?.name;
        if (!hasDefaultAreaOnly) {
          setCalculateStateAndRef((prevState) => {
            return {
              ...prevState,
              areaErrors: strictErrors
            };
          });
          if (!isError) {
            addElement({ calcByArea: areas[selectedArea]?.calcByArea || false });
          }
        }
        clickAddAreaAnalytics();
        onAddAreaClick(!hasDefaultAreaOnly && isError, hasDefaultAreaOnly ? 0 : areas.length, currentAreaName);
      }}
      variant={variant}
      disabled={disabled}
    >
      {
        selectedArea !== null
          ? 'Save and Add Another Area'
          : 'Add an Area'
      }
    </Button>
  );
};

AddAreaButton.displayName = 'AddAreaButton';

AddAreaButton.propTypes = {
  onAddAreaClick: func.isRequired,
  selectedArea: number,
  variant: string.isRequired,
  disabled: bool,
};

AddAreaButton.defaultProps = {
  selectedArea: null,
  disabled: false
};