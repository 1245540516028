import React, { useContext, useEffect, useRef } from 'react';
import { func, number } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorProvider';
import { checkForAnyErrors, hasError } from '../../../../helpers/errorHandlingUtil';
import { AddAreaButton } from '../sharedButtons/AddAreaButton';
import { saveAreaAnalytics } from '../../../../analytics';

export const SingleAreaCalculatorFooter = ({ onAddAreaClick, onSave, selectedArea }) => {
  const {
    addSavedAreaToaster,
    addUnsavedChangesToaster,
    calculate,
    calculatorType,
    calculateTotal,
    lineItemName,
    setCalculateStateAndRef
  } = useContext(CalculatorContext);

  const { areas, areaErrors } = calculate;
  const isErr = Object.values(areaErrors[selectedArea]).some((value) => value);
  const areaNameEdited = areas[selectedArea].name !== `${lineItemName} ${selectedArea + 1}`;
  const calcByArea = areas[selectedArea].calcByArea;

  // Assigning the error to ref to keep the error value on unmount
  const errorRef = useRef(isErr);

  useEffect(() => {
    errorRef.current = isErr;
  }, [isErr]);

  useEffect(() => {
    // When unmounting on footer (which is hitting back or close on the drawer) and there is an error,
    // trigger an unsaved toaster message
    return () => {
      if (errorRef.current) {
        addUnsavedChangesToaster({ selectedArea });
      }
    };
  }, []);

  return (
    <>
      <Button
        fullWidth
        variant="primary"
        onClick={() => {
          const strictErrors = checkForAnyErrors({
            calculatorType, areas
          });
          // update calculate state with updated errors
          setCalculateStateAndRef((prevState) => {
            return {
              ...prevState,
              areaErrors: strictErrors
            };
          });

          if (!hasError(areas, strictErrors)) {
            addSavedAreaToaster({ selectedArea });
            calculateTotal({ calculateOverride: calculate, isClick: true });
            onSave(areas[selectedArea].name);
            saveAreaAnalytics({ areaNameEdited, calcByArea, isAddingNewArea: false });
          }
        }}
        disabled={isErr}
      >
        Save and Finish This Area
      </Button>
      <AddAreaButton
        onAddAreaClick={(isError, areaLength, currentAreaName) => {
          if (!isError) {
            addSavedAreaToaster({ selectedArea });
            saveAreaAnalytics({ areaNameEdited, calcByArea, isAddingNewArea: true });
          }
          onAddAreaClick(isError, areaLength, currentAreaName);
        }}
        selectedArea={selectedArea}
        variant="secondary"
        disabled={isErr}
      />
    </>
  );
};

SingleAreaCalculatorFooter.displayName = 'SingleAreaCalculatorFooter';

SingleAreaCalculatorFooter.propTypes = {
  onAddAreaClick: func.isRequired,
  onSave: func.isRequired,
  selectedArea: number
};

SingleAreaCalculatorFooter.defaultProps = {
  selectedArea: null
};