import React from 'react';
import { string } from 'prop-types';

import { TrashCan } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';

export const DeleteConfirmationBody = ({ affectedAreaName }) => (
  <div className={'sui-w-full sui-h-full sui-flex sui-flex-col sui-justify-start sui-items-center sui-text-center'
          + ' sui-mt-24'}
  >
    <div className={'sui-flex sui-justify-center sui-items-center sui-w-28 sui-h-28'
        + ' sui-rounded-full sui-bg-danger-strong sui-text-11xl'}
    >
      <TrashCan size="inherit" color="inverse" width="66px" height="68px" />
    </div>
    <div className="sui-w-full sui-break-words sui-pt-6">
      <Typography variant="h4">
        {
          affectedAreaName
            ? `Delete ${affectedAreaName}?`
            : 'Delete All Areas?'
        }
      </Typography>
    </div>
    <div className="sui-pt-2">
      <Typography>
        {affectedAreaName ? 'This area' : 'All areas'} will be permanently deleted
      </Typography>
    </div>
  </div>
);

DeleteConfirmationBody.displayName = 'DeleteConfirmationBody';

DeleteConfirmationBody.propTypes = {
  affectedAreaName: string
};

DeleteConfirmationBody.defaultProps = {
  affectedAreaName: ''
};
