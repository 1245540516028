export const saveAreaAnalytics = ({ areaNameEdited, calcByArea, isAddingNewArea }) => {
  const areaType = 'room'; // TODO: should add "stairs" when stairs are implemented
  const cta = isAddingNewArea ? 'save and add another area' : 'save and finish this area';
  let dimensionType;
  if (areaType === 'room') {
    dimensionType = calcByArea ? 'square footage' : 'length x width';
  } else {
    dimensionType = 'number of steps';
  }

  window.LIFE_CYCLE_EVENT_BUS.trigger('project-estimate-overlay.save-area', {
    areaNameEdited,
    areaType,
    cta,
    dimensionType: calcByArea ? 'square footage' : 'length x width',
  });
};

export const clickAddAreaAnalytics = () => {
  window.LIFE_CYCLE_EVENT_BUS.trigger('project-estimate-overlay.click-add-area');
};