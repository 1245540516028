import React from 'react';
import { func } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { AddAreaButton } from '../sharedButtons/AddAreaButton';

export const RoomAreasFooter = ({ onAddAreaClick, onSaveAndClose }) => (
  <>
    <Button
      fullWidth
      variant="primary"
      onClick={() => onSaveAndClose()}
    >
      Save and Close
    </Button>
    <AddAreaButton onAddAreaClick={onAddAreaClick} variant="secondary" />
  </>
);

RoomAreasFooter.displayName = 'RoomAreasFooter';

RoomAreasFooter.propTypes = {
  onAddAreaClick: func.isRequired,
  onSaveAndClose: func.isRequired
};
