import React, { useRef, useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader
} from '@one-thd/sui-atomic-components';
import {
  DeleteConfirmationBody, DeleteConfirmationFooter,
  RoomAreasBody, RoomAreasFooter,
  SingleAreaCalculatorBody, SingleAreaCalculatorFooter,
  ToasterFooter,
  getCalculatorLocalStorage
} from '@thd-olt-component-react/calculator';

import { overlayAnalytics } from '../../analytics';
import {
  ADD_AREA,
  DELETE_ALL_INPUTS_CONFIRMATION,
  ESTIMATE_YOUR_PROJECT,
  LANDING
} from '../../constants';

const CalculatorDrawer = ({ drawerOpen, setDrawerOpen, label }) => {
  const { storageObj } = getCalculatorLocalStorage({ l1Label: label });

  const lastSelectedAreaNameRef = useRef('');
  const [menuName, setMenuName] = useState(storageObj ? LANDING : ADD_AREA);
  const [selectedArea, setSelectedArea] = useState(!storageObj ? 0 : null);

  const isLanding = menuName === LANDING;

  useEffect(() => {
    overlayAnalytics();
  }, []);

  useEffect(() => {
    if (isLanding) {
      setSelectedArea(null);
    }
  }, [menuName]);

  const reset = () => {
    setMenuName(storageObj ? LANDING : ADD_AREA);
    setSelectedArea(!storageObj ? 0 : null);
    if (!storageObj) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('calculator.localStorageUpdated');
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('flooringPicker.flooringPicker_toggle', { toggleOn: false });
    }
  };

  // Drawer functions
  const onBack = !isLanding && storageObj ? () => reset() : undefined;

  const onClose = () => {
    reset();
    setDrawerOpen(false);
  };

  // Body/Footer functions
  const onAddAreaClick = (isError, newAreaIndex, currentAreaName = '') => {
    if (!isError) {
      if (currentAreaName) {
        lastSelectedAreaNameRef.current = currentAreaName;
      }
      setSelectedArea(newAreaIndex);
      setMenuName(ADD_AREA);
    }
  };

  const onDelete = (areaName = '', index = null) => {
    lastSelectedAreaNameRef.current = areaName;
    if (index !== null) {
      setSelectedArea(index);
    }
    setMenuName(DELETE_ALL_INPUTS_CONFIRMATION);
  };

  const onSave = (currentAreaName) => {
    lastSelectedAreaNameRef.current = currentAreaName;
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('flooringPicker.flooringPicker_toggle', { toggleOn: true });
    setMenuName(LANDING);
  };

  const drawerBodyContent = {
    [LANDING]: <RoomAreasBody
      onClick={(index) => {
        setSelectedArea(index);
        setMenuName(ADD_AREA);
      }}
      onDelete={onDelete}
    />,
    [ADD_AREA]: <SingleAreaCalculatorBody selectedArea={selectedArea} />,
    [DELETE_ALL_INPUTS_CONFIRMATION]: <DeleteConfirmationBody affectedAreaName={lastSelectedAreaNameRef.current} />
  };

  const drawerFooterContent = {
    [LANDING]: <RoomAreasFooter onAddAreaClick={onAddAreaClick} onSaveAndClose={onClose} />,
    [ADD_AREA]: <SingleAreaCalculatorFooter
      onAddAreaClick={onAddAreaClick}
      onSave={onSave}
      selectedArea={selectedArea}
    />,
    [DELETE_ALL_INPUTS_CONFIRMATION]: (
      <DeleteConfirmationFooter
        onCancel={reset}
        onConfirm={reset}
        selectedArea={selectedArea}
      />
    )
  };

  return (
    <>
      {/* TODO: Drawer currently doesn't close when clicking away from it as expected. */}
      <Drawer open={drawerOpen} onClose={onClose} fixed>
        <DrawerHeader
          title={ESTIMATE_YOUR_PROJECT}
          onBack={onBack}
          onClose={onClose}
        />
        <DrawerBody>
          {drawerBodyContent[menuName]}
        </DrawerBody>
        <DrawerFooter>
          <div className="sui-grid sui-gap-y-2">
            <ToasterFooter />
            {drawerFooterContent[menuName]}
          </div>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

CalculatorDrawer.displayName = 'CalculatorDrawer';

CalculatorDrawer.propTypes = {
  drawerOpen: bool.isRequired,
  setDrawerOpen: func.isRequired,
  label: string.isRequired
};

export default CalculatorDrawer;