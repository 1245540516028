import React, { useContext } from 'react';

import { ToasterContext } from './ToasterProvider';
import { Toaster } from './Toaster';

export const ToasterFooter = () => {
  const { toasterKVPs } = useContext(ToasterContext);

  return (
    <>
      {
        Object.keys(toasterKVPs).map((toasterKey) => {
          const {
            action, children, message, showCloseButton, status
          } = toasterKVPs[toasterKey];
          return (
            <Toaster
              key={message}
              action={action}
              message={message}
              showCloseButton={showCloseButton}
              timeoutKey={toasterKey}
              status={status}
            >
              {children}
            </Toaster>
          );
        })
      }
    </>
  );
};

ToasterFooter.displayName = 'ToasterFooter';