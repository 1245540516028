import React, { useContext } from 'react';
import { func, number } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorProvider';

export const DeleteConfirmationFooter = ({ onCancel, onConfirm, selectedArea }) => {
  const {
    addRemovedAreaToaster,
    removeInputElement
  } = useContext(CalculatorContext);

  return (
    <>
      <Button
        onClick={() => {
          addRemovedAreaToaster({ selectedArea });
          removeInputElement({ index: selectedArea, shouldRenameAreas: false });
          onConfirm();
        }}
        fullWidth
        variant="primary"
      >
        Yes, Delete {selectedArea !== null ? 'Area' : 'All'}
      </Button>
      <Button
        onClick={onCancel}
        fullWidth
        variant="secondary"
      >
        No Thanks
      </Button>
    </>
  );
};

DeleteConfirmationFooter.displayName = 'DeleteConfirmationFooter';

DeleteConfirmationFooter.propTypes = {
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  selectedArea: number
};

DeleteConfirmationFooter.defaultProps = {
  selectedArea: null
};
