import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { func } from 'prop-types';

import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Link, Card, Typography, IconButton, Tooltip
} from '@one-thd/sui-atomic-components';
import { TrashCan, Info } from '@one-thd/sui-icons';
import { getCalculatorLocalStorage } from '../../../../helpers/localStorageUtil';
import { getDefaultErrorSchema, checkForAnyErrors, hasError } from '../../../../helpers/errorHandlingUtil';
import { FLOORING_CALC_DISCLAIMER } from '../../../../constants';

import { CalculatorContext } from '../../CalculatorProvider';

export const RoomAreasBody = ({ onClick, onDelete }) => {
  const { channel } = useContext(ExperienceContext);

  const {
    calculate,
    calculateTotal,
    calculatorType,
    l1Label
  } = useContext(CalculatorContext);
  const { areas, coverage } = calculate;

  useEffect(() => {
    const strictErrors = checkForAnyErrors({
      calculatorType, areas
    });
    const isError = hasError(areas, strictErrors);

    // If there's an error, re-instantiate the calculator with the values from local storage
    if (isError) {
      const { storageObj } = getCalculatorLocalStorage({ l1Label });
      if (storageObj) {
        const newCalculate = {
          ...calculate,
          ...storageObj,
          areaErrors: new Array(storageObj.areas.length).fill('').map(() => getDefaultErrorSchema()),
          isPresentInLocalStorage: true
        };
        calculateTotal({ calculateOverride: newCalculate, isClick: false });
      }
    }
  }, []);

  const isMobile = channel === 'mobile';
  const noAreasMessagingClasses = classNames('sui-w-full sui-h-full sui-grid sui-justify-center sui-content-center', {
    'sui-h-80': isMobile
  });

  return (
    <>
      <div className="sui-w-full sui-flex sui-items-center sui-pb-2">
        <div className="sui-w-1/2 sui-line-clamp-1 sui-flex sui-flex-row">
          <Typography variant="h3">Total Area</Typography>
          <Tooltip offset="normal" title={FLOORING_CALC_DISCLAIMER}>
            <IconButton icon={Info} color="info" />
          </Tooltip>
        </div>
        <div className="sui-w-1/2 sui-justify-end">
          <Typography align="right" variant="h4">{coverage} sq. ft.</Typography>
        </div>
      </div>
      {coverage === 0 && (
        <div className={noAreasMessagingClasses}>
          <Typography align="center" color="subtle" variant="h4">There&apos;s Nothing Here</Typography>
          <Typography align="center" color="subtle">Add an area to get started</Typography>
        </div>
      )}
      {/* TODO: UX still pending */}
      {coverage > 0 && (
        <>
          {/* TODO: Still waiting on UX to determine the look and behavior of these room areas. */}
          <div className="sui-pb-4">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              color="info"
              component="button"
              onClick={() => onDelete()}
              underline="none"
            >
              Delete All
            </Link>
          </div>
          {areas?.map(({ length, name, squareFootage, width }, index) => {
            const measurementText = length && width
              ? `Measurements: ${length} ft. x ${width} ft.`
              : 'Measurements: L x W not entered';
            return (
              <Card
                key={index}
                PaperProps={{
                  className: 'sui-mb-4'
                }}
              >
                <div>
                  <Typography truncate variant="h4">{name}</Typography>
                  <Typography variant="body-base">{squareFootage} sq. ft.</Typography>
                </div>
                <Typography color="subtle">{measurementText}</Typography>
                <div className="sui-flex sui-w-full">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    align="left"
                    component="button"
                    onClick={() => onClick(index)}
                  >
                    Edit
                  </Link>
                  <div className="sui-flex sui-w-full sui-justify-end">
                    <IconButton
                      aria-label="TrashCanIcon"
                      icon={TrashCan}
                      onClick={() => onDelete(name, index)}
                    />
                  </div>
                </div>

              </Card>
            );
          })}
        </>
      )}
    </>
  );
};

RoomAreasBody.propTypes = {
  onClick: func.isRequired,
  onDelete: func.isRequired
};
