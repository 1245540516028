import React, { useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import { number } from 'prop-types';

import { IconButton, Typography } from '@one-thd/sui-atomic-components';
import { Cancel, Checkmark, Edit } from '@one-thd/sui-icons';

import { hasError } from '../../../../helpers/errorHandlingUtil';
import { CalculateByDimensions } from '../../../CalculateByDimensions';
import { CalculatorContext } from '../../CalculatorProvider';
import { CalculatorInputElement } from '../../CalculatorInputElement';
import { CalculatorInputSections } from '../../../CalculatorInputSections';
import { DEFAULT_CALC_CLASSES } from '../../../../constants';

export const SingleAreaCalculatorBody = ({ selectedArea }) => {
  const {
    calculate: {
      areas,
      areaErrors,
    },
    setCalculateStateAndRef
  } = useContext(CalculatorContext);
  const [editActive, setEditActive] = useState(false);
  const prevNameRef = useRef(areas?.[selectedArea]?.name);

  const cancelClick = () => {
    const newAreas = [...areas];
    newAreas[selectedArea].name = prevNameRef.current;
    setCalculateStateAndRef((prevState) => {
      return {
        ...prevState,
        areas: newAreas,
        changesAwaitingLocalStorage: true
      };
    });
    setEditActive(false);
  };

  const editClick = () => {
    prevNameRef.current = areas[selectedArea].name;
    setEditActive(!editActive);
  };

  const checkmarkClasses = classNames({
    'sui-mt-2': !hasError(areas, areaErrors),
    'sui-mb-2': hasError(areas, areaErrors)
  });
  const dimensionNames = ['Length', 'Width'];

  return (
    <div className={`${DEFAULT_CALC_CLASSES} sui-w-full`}>
      {selectedArea !== null && !editActive && (
        <div className="sui-grid sui-grid-cols-12 sui-items-center sui-mb-3">
          <span className="sui-pr-3 sui-col-span-11">
            <Typography truncate variant="h3">{areas[selectedArea].name}</Typography>
          </span>
          <IconButton icon={Edit} aria-label="EditIcon" onClick={() => editClick()} />
        </div>
      )}
      {selectedArea !== null && editActive && (
        <div className="sui-flex sui-items-center sui-mb-3 sui-w-full">
          <CalculatorInputElement
            index={selectedArea}
            propName="Description"
            hideLabel
            propValue={areas[selectedArea].name}
            isError={areaErrors[selectedArea].name}
            endAdornment={(
              <IconButton
                aria-label="CancelIcon"
                icon={Cancel}
                onClick={() => cancelClick()}
              />
            )}
          />
          <div className={checkmarkClasses}>
            <IconButton
              aria-label="CheckMarkIcon"
              icon={Checkmark}
              onClick={() => setEditActive(!editActive)}
            />
          </div>
        </div>
      )}
      <CalculateByDimensions dimensionNames={dimensionNames} selectedArea={selectedArea} />
      <CalculatorInputSections dimensionNames={dimensionNames} selectedArea={selectedArea} hideAreaText />
    </div>
  );
};

SingleAreaCalculatorBody.displayName = 'SingleAreaCalculatorBody';

SingleAreaCalculatorBody.propTypes = {
  selectedArea: number
};

SingleAreaCalculatorBody.defaultProps = {
  selectedArea: null
};